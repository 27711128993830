.Link {
  color: var(--color-link);
  cursor: pointer;
  max-width: min-content;
  position: relative;
  white-space: nowrap;
}

.Link::before,
.Link::after {
  background: currentColor;
  height: 1px;
  top: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
}

.Link::before {
  content: "";
  transform-origin: 0% 50%;
  transform: scale3d(0, 1, 1);
  transition: transform 0.3s;
}

.Link:hover::before {
  transform: scale3d(1, 1, 1);
}

.Link::after {
  content: "";
  top: calc(100% + 4px);
  transition: transform 0.3s;
  transform-origin: 100% 50%;
}

.Link:hover::after {
  transform: scale3d(0, 1, 1);
}
