@import url(https://use.typekit.net/itm0qdj.css);
.ContentContainer {
  max-width: 50vw;
  width: 100%;
}

.ContentContainer--sm {
  max-width: 25ch;
}

.ContentContainer--md {
  max-width: 50ch;
}

.ContentContainer--lg {
  max-width: 75ch;
}

.Footer {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
}

.Footer-text {
  font-size: 1rem;
}

.GradientCanvas {
  /* border: 1.25rem solid var(--color-border); */
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
}

.GradientCanvas-filter {
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.Heading {
  font-family: var(--sans-serif);
  font-weight: 300;
  margin: 0;
}

.Heading--display {
  font-family: var(--display);
}

.Heading--sm {
  font-size: 1.75rem;
  line-height: 1.3;
}

.Heading--md {
  font-size: 2rem;
  line-height: 1.3;
}

@media screen and (min-width: 1000px) {
  .Heading--md {
    font-size: 5vmin;
  }
}

.Hero {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 8rem 3vmax 3rem;
  width: 100%;
}

.Hero-heading {
  font-size: clamp(4rem, 11vmax, 8rem);
  line-height: 1;
}

.Hero-subhead {
  max-width: 60ch;
}

@media screen and (min-width: 700px) {
  .Hero {
    padding-left: 5vmax;
    padding-right: 5vmax;
  }
}

@media screen and (min-width: 1000px) {
  .Hero-subhead {
    font-size: 2vmax;
  }
}

.Layout {
  display: grid;
  grid-template-areas:
    "hero"
    "main"
    "aside"
    "footer";
  grid-template-columns: 1fr;
  grid-template-rows: -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-rows: max-content max-content max-content;
}

.Layout-hero {
  grid-area: hero;
}

.Layout-aside {
  background-color: var(--color-background);
  grid-area: aside;
  padding: 1rem 3vmax 3rem;
}

.Layout-main {
  background-color: var(--color-background);
  grid-area: main;
  padding: 3rem 3vmax 0;
}

.Layout-footer {
  border-top: 1px solid var(--color-border);
  background-color: var(--color-background);
  grid-area: footer;
  padding: 1rem 3vmax;
}

@media screen and (min-width: 700px) {
  .Layout {
    display: grid;
    grid-template-areas:
      "hero hero"
      "main aside"
      "footer footer";
    grid-template-columns: 3fr 2fr;
    grid-template-rows: 80vh -webkit-max-content -webkit-max-content;
    grid-template-rows: 80vh max-content max-content;
  }

  .Layout-main {
    padding: 3rem 5vmax;
  }

  .Layout-aside {
    padding: 3rem 5vmax;
  }

  .Layout-footer {
    padding-left: 5vmax;
    padding-right: 5vmax;
  }
}

.Link {
  color: var(--color-link);
  cursor: pointer;
  max-width: -webkit-min-content;
  max-width: min-content;
  position: relative;
  white-space: nowrap;
}

.Link::before,
.Link::after {
  background: currentColor;
  height: 1px;
  top: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
}

.Link::before {
  content: "";
  -webkit-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
  -webkit-transform: scale3d(0, 1, 1);
          transform: scale3d(0, 1, 1);
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.Link:hover::before {
  -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
}

.Link::after {
  content: "";
  top: calc(100% + 4px);
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-transform-origin: 100% 50%;
          transform-origin: 100% 50%;
}

.Link:hover::after {
  -webkit-transform: scale3d(0, 1, 1);
          transform: scale3d(0, 1, 1);
}

.Nav {
  display: flex;
  flex-direction: column;
  grid-gap: 3rem;
  gap: 3rem;
  position: -webkit-sticky;
  position: sticky;
  top: 4vmax;
}

.Nav-group {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.Nav-list {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.Nav-listItem {
  display: block;
}

.Section {
  border-bottom: 1px solid var(--color-border-secondary);
  padding: var(--spacing-md);
  width: 100%;
}

:root {
  /* Color */
  --color-black: #080c12;
  --color-white: #f2d9c0;
  /* Type */
  --serif: swear-display, serif;
  --sans-serif: owners-text, sans-serif;
  --display: swear-display-cilati, sans-serif;
  /* Spacing */
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;
  --spacing-xxl: 3rem;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

p {
  font-family: owners-text, sans-serif;
  font-family: var(--sans-serif);
  font-size: 1.25rem;
  font-weight: 300;
}

a {
  color: var(--color-link);
  font-family: owners-text, sans-serif;
  font-family: var(--sans-serif);
  font-size: 1.25rem;
  font-weight: 300;
  transition: color 200ms;
  text-decoration: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  font-family: owners-text, sans-serif;
  font-family: var(--sans-serif);
  font-size: 1.125rem;
  font-weight: 400;
}

.App {
  color: var(--color-text);
  height: 100vh;
  position: relative;
  width: 100vw;
}

.App--light {
  --color-background: var(--color-white);
  --color-border: var(--color-black);
  --color-border-secondary: var(--color-black);
  --color-link: #5c3352;
  --color-text: var(--color-black);
}

.App--dark {
  --color-background: var(--color-black);
  --color-border: var(--color-white);
  --color-border-secondary: var(--color-white);
  --color-link: #dbbdd4;
  --color-text: var(--color-white);
}

.App-wrapper {
  position: relative;
  z-index: 2;
}

.u--stack {
  display: flex;
  flex-direction: column;
}

.App--light .ToggleColorBtn-icon::after {
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.App--dark .ToggleColorBtn-icon::after {
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

.ToggleColorBtn {
  align-items: center;
  -webkit-appearance: none;
          appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  height: 3rem;
  justify-content: center;
  position: absolute;
  top: 1vw;
  right: 1vw;
  width: 3rem;
}

.ToggleColorBtn-icon {
  border: 2px solid var(--color-border);
  border-radius: 50%;
  display: block;
  height: 1.5rem;
  position: relative;
  width: 1.5rem;
}

.ToggleColorBtn-icon::after {
  background: linear-gradient(
    to left,
    var(--color-border) 0%,
    var(--color-border) 50%,
    transparent 50%,
    transparent 100%
  );
  border-radius: 50%;
  content: "";
  display: block;
  height: 1.5rem;
  left: -2px;
  position: absolute;
  top: -2px;
  width: 1.5rem;
}

