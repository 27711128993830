.Layout {
  display: grid;
  grid-template-areas:
    "hero"
    "main"
    "aside"
    "footer";
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content max-content;
}

.Layout-hero {
  grid-area: hero;
}

.Layout-aside {
  background-color: var(--color-background);
  grid-area: aside;
  padding: 1rem 3vmax 3rem;
}

.Layout-main {
  background-color: var(--color-background);
  grid-area: main;
  padding: 3rem 3vmax 0;
}

.Layout-footer {
  border-top: 1px solid var(--color-border);
  background-color: var(--color-background);
  grid-area: footer;
  padding: 1rem 3vmax;
}

@media screen and (min-width: 700px) {
  .Layout {
    display: grid;
    grid-template-areas:
      "hero hero"
      "main aside"
      "footer footer";
    grid-template-columns: 3fr 2fr;
    grid-template-rows: 80vh max-content max-content;
  }

  .Layout-main {
    padding: 3rem 5vmax;
  }

  .Layout-aside {
    padding: 3rem 5vmax;
  }

  .Layout-footer {
    padding-left: 5vmax;
    padding-right: 5vmax;
  }
}
