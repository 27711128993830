@import url("https://use.typekit.net/itm0qdj.css");

:root {
  /* Color */
  --color-black: #080c12;
  --color-white: #f2d9c0;
  /* Type */
  --serif: swear-display, serif;
  --sans-serif: owners-text, sans-serif;
  --display: swear-display-cilati, sans-serif;
  /* Spacing */
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;
  --spacing-xxl: 3rem;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

p {
  font-family: var(--sans-serif);
  font-size: 1.25rem;
  font-weight: 300;
}

a {
  color: var(--color-link);
  font-family: var(--sans-serif);
  font-size: 1.25rem;
  font-weight: 300;
  transition: color 200ms;
  text-decoration: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  font-family: var(--sans-serif);
  font-size: 1.125rem;
  font-weight: 400;
}

.App {
  color: var(--color-text);
  height: 100vh;
  position: relative;
  width: 100vw;
}

.App--light {
  --color-background: var(--color-white);
  --color-border: var(--color-black);
  --color-border-secondary: var(--color-black);
  --color-link: #5c3352;
  --color-text: var(--color-black);
}

.App--dark {
  --color-background: var(--color-black);
  --color-border: var(--color-white);
  --color-border-secondary: var(--color-white);
  --color-link: #dbbdd4;
  --color-text: var(--color-white);
}

.App-wrapper {
  position: relative;
  z-index: 2;
}

.u--stack {
  display: flex;
  flex-direction: column;
}

.App--light .ToggleColorBtn-icon::after {
  transition: transform 0.2s ease-out;
  transform: rotate(180deg);
}

.App--dark .ToggleColorBtn-icon::after {
  transition: transform 0.2s ease-out;
  transform: rotate(0deg);
}

.ToggleColorBtn {
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  height: 3rem;
  justify-content: center;
  position: absolute;
  top: 1vw;
  right: 1vw;
  width: 3rem;
}

.ToggleColorBtn-icon {
  border: 2px solid var(--color-border);
  border-radius: 50%;
  display: block;
  height: 1.5rem;
  position: relative;
  width: 1.5rem;
}

.ToggleColorBtn-icon::after {
  background: linear-gradient(
    to left,
    var(--color-border) 0%,
    var(--color-border) 50%,
    transparent 50%,
    transparent 100%
  );
  border-radius: 50%;
  content: "";
  display: block;
  height: 1.5rem;
  left: -2px;
  position: absolute;
  top: -2px;
  width: 1.5rem;
}
