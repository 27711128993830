.ContentContainer {
  max-width: 50vw;
  width: 100%;
}

.ContentContainer--sm {
  max-width: 25ch;
}

.ContentContainer--md {
  max-width: 50ch;
}

.ContentContainer--lg {
  max-width: 75ch;
}
