.GradientCanvas {
  /* border: 1.25rem solid var(--color-border); */
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
}

.GradientCanvas-filter {
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
