.Footer {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
}

.Footer-text {
  font-size: 1rem;
}
