.Nav {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  position: sticky;
  top: 4vmax;
}

.Nav-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.Nav-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.Nav-listItem {
  display: block;
}
