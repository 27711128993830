.Heading {
  font-family: var(--sans-serif);
  font-weight: 300;
  margin: 0;
}

.Heading--display {
  font-family: var(--display);
}

.Heading--sm {
  font-size: 1.75rem;
  line-height: 1.3;
}

.Heading--md {
  font-size: 2rem;
  line-height: 1.3;
}

@media screen and (min-width: 1000px) {
  .Heading--md {
    font-size: 5vmin;
  }
}
