.Hero {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 8rem 3vmax 3rem;
  width: 100%;
}

.Hero-heading {
  font-size: clamp(4rem, 11vmax, 8rem);
  line-height: 1;
}

.Hero-subhead {
  max-width: 60ch;
}

@media screen and (min-width: 700px) {
  .Hero {
    padding-left: 5vmax;
    padding-right: 5vmax;
  }
}

@media screen and (min-width: 1000px) {
  .Hero-subhead {
    font-size: 2vmax;
  }
}
